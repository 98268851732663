<template>
  <v-card flat tile :loading="loading" height="100%">
    <div class="container">
      <div v-if="data" v-show="selectedSolutionId == null">
        <v-toolbar flat dense>
          <v-toolbar-title>
            {{
              data.Solutions
                ? `${$t("selectsolution")}`
                : `${$t("step")} ${data.Step}: ${data.Title}`
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-if="data.Selected.length > 0">
            <v-btn
              outlined
              depressed
              small
              v-on:click="prevStep(getPreviousStepIndex())"
              class="mr-2"
            >
              <v-icon color="primary" small>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn small outlined depressed v-on:click="reset()">
              <v-icon color="primary" small>mdi-redo</v-icon>
            </v-btn>
          </template>
        </v-toolbar>
        <breadCrumb
          :productType="productType"
          :brand="brand"
          :product="product"
          :selectedTaxonomies="selectedTaxonomies"
          @prevStep="prevStep"
          :selectedAutoSkipped="this.data.SelectedAutoSkipped"
        />
        <!-- <p>
          <template v-if="productType">
            <a @click="toProductTypeChoice"> {{ productType.Name }}</a>
            &nbsp; / &nbsp;
          </template>
          <template v-if="brand && product">
            <a @click="toBrandChoice"> {{ brand.Name }}</a>
            &nbsp; / &nbsp;
            <a @click="toProductChoice"> {{ product.Name }}</a>
            &nbsp; / &nbsp;
          </template>
          <template v-for="(step, i) in selectedTaxonomies">
            <template v-if="step && step !== 'Niet van toepassing'">
              <span :key="i">
                <a @click="prevStep(i)" v-if="!data.SelectedAutoSkipped[i]">
                  {{ step }}
                </a>
                <span v-else>{{ step }}</span>
                &nbsp; / &nbsp;
              </span>
            </template>
          </template>
        </p> -->
        <v-progress-linear
          v-model="linearProgressBar"
          @click.stop
          color="blue darken-2"
        ></v-progress-linear>
        <div v-if="data.Options">
          <div class="wrapper_options mt-3">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-avatar-three-line"
              v-for="(tax, i) in data.Options.filter((x) => x !== null)"
              :key="i"
            >
              <template v-if="tax !== null">
                <!-- this should be fixed -->
                <v-card
                  outlined
                  v-if="data.Options"
                  v-on:click="steps(tax.Id, tax.Name)"
                  class="option wrapper_content_taxonomy"
                >
                  <div class="option image">
                    <v-img
                      aspect-ratio="1"
                      width="100px"
                      height="100px"
                      :src="tax.Image"
                    ></v-img>
                  </div>
                  <div class="subtitle-1 font-weight-bold option">
                    {{ tax.Name }}
                  </div>
                </v-card>
              </template>
            </v-skeleton-loader>
          </div>
        </div>
        <div v-else-if="data.Solutions && data.Solutions.length > 0">
          <thumbnailSolutions
            :solutions="data.Solutions"
            :isFirelog="isFirelog"
            :selectorSteps="data"
            :productType="productType"
            :brand="brand"
            :product="product"
            :selectedStepsFromSelector="selectedTaxonomies"
            :selectedStepIdsFromSelector="data.Selected"
            :selectedAutoSkipped="data.SelectedAutoSkipped"
          />
        </div>
        <div v-else class="title mt-6 text-center overline mx-auto">
          {{ $t("noresults") }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import ajax from "@/services/service.oryx.js";
// import selectedSolutionComponent from "@/components/pages/Markers/SolutionSelector/Detail";
import jwtdecode from "jwt-decode";
import thumbnailSolutions from "@/components/ThumbnailSolutions";
import breadCrumb from "@/components/BreadCrumb";
import { mapState } from "vuex";

export default {
  props: {
    // brand: Object,
    // product: Object,
    // productType: Object,
    dialog: Object,
    isFirelog: Boolean,
    comeBackToSelector: {
      type: Object,
      required: false,
    },
    selectedTaxonomyIdsFromDetailView: Array,
    selectedAutoSkippedFromDetailView: Array,
  },
  data() {
    return {
      loading: true,
      data: null,
      selectedSolutionId: null,
      tax263415Id: null,
      dialogParent: this.dialog,
      selectedTaxonomies: [],
    };
  },
  components: {
    thumbnailSolutions,
    breadCrumb,
    // selectedSolutionComponent,
  },
  created() {
    // console.log(this.comeBackToSelector);
    if (
      this.comeBackToSelector != null &&
      this.comeBackToSelector != undefined
    ) {
      this.data = JSON.parse(JSON.stringify(this.comeBackToSelector));
      // console.log(this.data);
    }

    //this.reset();
    this.steps();
  },
  computed: {
    linearProgressBar() {
      return (this.data.Selected.length / 9) * 100;
    },
    brand() {
      return this.choices.brand;
    },
    product() {
      return this.choices.product;
    },
    productType() {
      return this.choices.productType;
    },
    ...mapState({ choices: "solutionSelectorChoices" }),
  },
  methods: {
    // selectedSolution(solution) {
    //   console.log(`Routing ongoing...`)
    //   console.log(this.data);

    //   this.$router.push({
    //     name: "Detail",
    //     params: {
    //       id: solution.Id,
    //       taxId: solution.Tax263415Id,
    //       isFirelog: this.isFirelog,
    //       selectorSteps: this.data,
    //     },
    //   });
    // },
    useInMarker(solution, taxonomies) {
      this.$emit("selectedSolution", {
        solution: solution,
        taxonomies: taxonomies,
      });
      this.dialogParent = false;
    },
    useInMarkerFromDetails(data) {
      this.dialogParent = false;
    },
    getPreviousStepIndex() {
      let index = this.selectedTaxonomies.length;
      while (
        index-- &&
        (!this.selectedTaxonomies[index] ||
          this.selectedTaxonomies[index] === "Niet van toepassing" ||
          this.data.SelectedAutoSkipped[index])
      );
      return index;
    },
    prevStep(sliceIndex) {
      if (sliceIndex && sliceIndex !== 0) {
        if (this.data.Selected[sliceIndex] === null) {
          sliceIndex++;
          if (this.data.Selected[sliceIndex] === null) {
            sliceIndex++;
            if (this.data.Selected[sliceIndex] === null) {
              sliceIndex++;
            }
          }
        }

        console.log(`sliceIndex is ${sliceIndex}`);

        this.data.Selected = this.data.Selected.slice(0, sliceIndex);
        this.selectedTaxonomies = this.selectedTaxonomies.slice(0, sliceIndex);
      } else {
        this.data.Selected = [];
        this.selectedTaxonomies = [];
      }

      this.steps();
    },
    insertIdinCorrectPosition(id) {
      if (this.data && id !== undefined) {
        this.data.Selected.push(id);
        this.data.SelectedAutoSkipped.push(false);
      }
    },
    steps(id, taxname) {
      this.loading = true;
      let user = jwtdecode(localStorage.getItem("auth"));
      this.insertIdinCorrectPosition(id);
      //if (this.data) this.data.Selected.push(id);
      // console.log(this.data);
      const params = {
        userId: user.id,
        productTypeId:
          this.productType && this.productType.Id ? this.productType.Id : null,
        brandId: this.brand && this.brand.Id ? this.brand.Id : null,
        productId: this.product && this.product.Id ? this.product.Id : null,
        taxIds: this.data
          ? this.data.Selected
          : this.selectedTaxonomyIdsFromDetailView
          ? this.selectedTaxonomyIdsFromDetailView
          : [],
        companyId: 3, //jwtdecode(localStorage.getItem("auth")).companyId,
        lc: "NL", //localStorage.getItem("language"),
        autoSkipped: this.data ? this.data.SelectedAutoSkipped : [],
      };

      // console.log(params);

      ajax
        .steps(params)
        .then((res) => {
          this.data = res.data;

          // console.log(this.data);

          // Set data.SelectedAutoSkipped if its length doesn't match data.Selected
          if (
            this.data.Selected.length > this.data.SelectedAutoSkipped.length
          ) {
            this.data.SelectedAutoSkipped = JSON.parse(
              JSON.stringify(this.selectedAutoSkippedFromDetailView)
            );
          }

          this.loading = false;

          //if (taxname) this.selectedTaxonomies.push(taxname);
          this.selectedTaxonomies = this.data.OptionsSelected;

          this.data.Selected.forEach((selected, i) => {
            if (selected == null && this.selectedTaxonomies[i] !== null) {
              this.selectedTaxonomies.splice(i, 0, null);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    reset() {
      this.$store.commit("resetSelectedSolution");
      this.data = null;
      this.selectedTaxonomies = [];
      this.steps();
    },
    favorite(solution) {
      // console.log(solution);
      let user = jwtdecode(localStorage.getItem("auth"));
      const params = {
        userId: user.id,
        Taxonomy26734158Id: solution.Tax263415Id,
      };
      // console.log("Params: ", params);
      ajax.createFavorite(params).then((res) => {
        // console.log(res);
        if (res.data == "Deleted") {
          solution.IsFavorite = false;
        } else if (res.data == "Created") {
          solution.IsFavorite = true;
        }
      });
    },
  },
  watch: {
    dialog(n, o) {
      this.dialogParent = n;
    },
    dialogParent(n, o) {
      this.$emit("dialogChange", this.dialogParent);
    },
    brand(n, o) {
      this.reset();
    },
    product(n, o) {
      this.reset();
    },
    productType(n, o) {
      this.reset();
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
}
.wrapper_options {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.wrapper_content_taxonomy {
  /*--auto-grid-min-size: 25rem;*/
  display: grid;

  grid-template-columns: 1fr 5fr;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_options:hover .wrapper_content_taxonomy {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_options:hover .wrapper_content_taxonomy:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* start solutions */
.wrapper_solutions {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.wrapper_content_solution {
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_solutions:hover .wrapper_content_solution {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_solutions:hover .wrapper_content_solution:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* end solutions */
.option {
  border-radius: 5px;
  padding: 1em;
  height: 100%;
}
.option .image {
  padding: 0;
}
.wrapper_content_taxonomy {
  padding: 0em;
}

.solution {
  border-radius: 5px;
  padding: 1em;
  height: auto;
  min-height: 88px;
}
.sticky_solutionSelector {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
