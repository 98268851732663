<template>
  <p>
    <template v-if="productType && productType.Id">
      <a @click="toProductTypeChoice"> {{ productType.Name }}</a>
      &nbsp; / &nbsp;
    </template>
    <template v-if="brand && brand.Id && product && product.Id">
      <a @click="toBrandChoice"> {{ brand.Name }}</a>
      &nbsp; / &nbsp;
      <a @click="toProductChoice"> {{ product.Name }}</a>
      &nbsp; / &nbsp;
    </template>
    <template v-for="(step, i) in selectedTaxonomies">
      <template v-if="step && step !== 'Niet van toepassing'">
        <span :key="i">
          <a
            @click="prevStep(i)"
            v-if="selectedAutoSkipped && !selectedAutoSkipped[i]"
          >
            {{ step.Name ? step.Name : step }}
          </a>
          <a
            @click="prevStep(i)"
            v-else-if="!selectedAutoSkipped && isDetailView"
          >
            {{ step.Name ? step.Name : step }}
          </a>
          <span v-else>{{ step.Name ? step.Name : step }}</span>
          &nbsp; / &nbsp;
        </span>
      </template>
    </template>
  </p>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: [
    // "productType",
    // "brand",
    // "product",
    "selectedTaxonomies",
    "selectedAutoSkipped",
    "isDetailView",
  ],
  computed: {
    brand() {
      return this.choices.brand;
    },
    product() {
      return this.choices.product;
    },
    productType() {
      return this.choices.productType;
    },
    ...mapState({ choices: "solutionSelectorChoices" }),
  },
  methods: {
    prevStep(i) {
      this.$emit("prevStep", i);
    },
    toProductTypeChoice() {
      this.$router.push({
        name: "ProductTypeChoice",
      });
    },
    toBrandChoice() {
      this.$router.push({
        name: "BrandChoice",
      });
    },
    toProductChoice() {
      this.$router.push({
        name: "ProductChoice",
        params: {
          brand: this.brand,
        },
      });
    },
  },
};
</script>
