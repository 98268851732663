<template>
  <!-------------------- MAKE SURE THIS IS IN A DIV WITH CLASS CONTAINER -------------------->
  <div class="wrapper_solutions mt-3" v-if="solutions">
    <v-card
      outlined
      class="wrapper_content_solution"
      v-for="(solution, id) in solutions"
      :key="id"
    >
      <v-card-text style="height: 100%">
        <v-img contain :src="solution.Link" height="360px"></v-img>
        <div class="title font-weight-bold">
          {{ solution.Name }}
          {{ solution.Number ? " (" + solution.Number + ")" : "" }}
        </div>
        <div class="subtitle-1 font-weight-bold" v-if="solution.Tax1">
          {{ $t("diameter") }}: {{ solution.Tax1 }}mm
        </div>
        <div class="subtitle-1 font-weight-bold">
          {{ $t("fireResistance") }}: {{ solution.Tax6 }}
          {{ $t("minutes") }}
        </div>
        <div class="subtitle-1 font-weight-bold">
          {{ $t("thickness") }}: {{ solution.Tax9 }}mm
        </div>
        <div class="subtitle-1 font-weight-bold" v-if="solution.WandDikte">
          {{ $t("wallThickness") }}: {{ solution.WandDikte }}mm
        </div>
        <div class="subtitle-1 font-weight-bold" v-if="solution.IsolatieDikte">
          {{ $t("insulationThickness") }}: {{ solution.IsolatieDikte }}mm
        </div>
        <div class="subtitle-1 font-weight-bold">
          <br v-if="!solution.Tax1" />
          <br v-if="!solution.WandDikte" />
          <br v-if="!solution.IsolatieDikte" />
        </div>
        <v-card-actions>
          <v-btn
            small
            depressed
            outlined
            color="primary"
            v-on:click="selectedSolution(solution)"
          >
            {{ $t("viewMore") }}
          </v-btn>
          <v-btn
            v-on:click="useInMarker(solution, data.Selected)"
            small
            v-if="isFirelog"
            depressed
            outlined
            color="secondary"
          >
            {{ $t("usethisinmarker") }}
          </v-btn>

          <v-spacer />
          <v-btn small depressed v-on:click="favorite(solution)">
            <v-icon small color="primary" v-if="solution.IsFavorite">
              mdi-star
            </v-icon>
            <v-icon small color="primary" v-else> mdi-star-outline </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ajax from "@/services/service.oryx.js";
import jwtdecode from "jwt-decode";
// import selectedSolutionComponent from "@/components/pages/Markers/SolutionSelector/Detail";

export default {
  props: [
    "isFirelog",
    "solutions",
    "selectorSteps",
    "selectedStepsFromSelector",
    "selectedStepIdsFromSelector",
    "selectedAutoSkipped",
  ],
  data() {
    return {
      // isFirelog: true,
      favorites: null,
      selectedSolutionId: null,
      tax263415Id: null,
    };
  },
  methods: {
    selectedSolution(solution) {
      console.log(`Routing ongoing...`);
      this.$store.commit("addSelectedSolution", {
        id: solution.Id,
        taxId: solution.Tax263415Id,
      });

      this.$router.push({
        name: "Detail",
        params: {
          // id: solution.Id,
          // taxId: solution.Tax263415Id,
          isFirelog: this.isFirelog,
          selectorSteps: this.selectorSteps,
          selectedStepsFromSelector: this.selectedStepsFromSelector,
          selectedStepIdsFromSelector: this.selectedStepIdsFromSelector,
          selectedAutoSkipped: this.selectedAutoSkipped,
        },
      });
    },
    useInMarkerFromDetails() {
      this.dialogParent = false;
    },
    favorite(solution) {
      //   console.log(solution);
      let user = jwtdecode(localStorage.getItem("auth"));
      const params = {
        userId: user.id,
        Taxonomy26734158Id: solution.Tax263415Id,
      };
      //   console.log("Params: ", params);
      ajax.createFavorite(params).then((res) => {
        if (res.data == "Deleted") {
          solution.IsFavorite = false;
        } else if (res.data == "Created") {
          solution.IsFavorite = true;
        }
        // console.log(res);
      });
    },
  },
};
</script>

<style scoped>
/* start solutions */
.wrapper_solutions {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.wrapper_content_solution {
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_solutions:hover .wrapper_content_solution {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_solutions:hover .wrapper_content_solution:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
</style>
